import React from "react";
import { Keyframes, animated, config } from "react-spring/renderprops";

const Content = Keyframes.Spring(async next => {
    // None of this will cause React to render, the component renders only once :-)
    while (true) {
        await next({
            from: { transform: "translate3d(0%,0%,0) scale(1) rotate(0deg)" },
            transform: "translate3d(200%,0%,0) scale(1.5) rotate(90deg)",
            backgroundColor: "#44e0a6",
            config: config.wobbly
        });
        await next({
            transform: "translate3d(200%,200%,0) scale(1) rotate(0deg)",
            backgroundColor: "#6696e6",
            config: config.wobbly
        });
        await next({
            transform: "translate3d(0%,200%,0) scale(1.5) rotate(90deg)",
            backgroundColor: "#2a2e51",
            config: config.wobbly
        });
        await next({
            transform: "translate3d(0%,0%,0) scale(1) rotate(0deg)",
            backgroundColor: "#1b1a36",
            config: config.wobbly
        });
    }
});

const ModalLoader =  () => (
    <div className="modal__slide">
        <div className="modal__container center">
            <h1 className="c__secondary pb__m">Loading...</h1>
            <div className="loader__container">
                <Content>
                    {props => (
                        <animated.div
                            className="loader"
                            style={{
                                ...props
                            }}
                        />
                    )}
                </Content>
            </div>
        </div>
    </div>
);

export default ModalLoader;