import React from "react";
import { useSpring, animated, config } from "react-spring";

// Components
import Image from "./image";

const Card = ({ src, index, activeIndex }) => {
    const isActive = activeIndex >= index;

    const props = useSpring({
        transform: isActive ? "scale(1)" : "scale(0.9)",
        config: config.stiff
    });

    return (
        <animated.div style={props} className="item">
            {isActive && <Image src={src} />}
        </animated.div>
    );
};

export default Card;   