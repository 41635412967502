import React from "react";

// - Components
import { IconError } from "../icons";

const ModalNavigation = ({ toggle }) => (
    <div className="modal__close flex flex__space wrap">
        <span />
        <button className="flex flex__align pt__m t__u fs__s" onClick={toggle}>
            <div>Sluiten (Esc)</div>
            <IconError className="icon icon__sm ml__s" />
        </button>
    </div>
);


export default ModalNavigation;