import React from "react";

// Components
import { ButtonPrimary } from "../buttons";

const Slide = ({
	children,
	index,
	required,
	value,
	currentIndex,
	previousIndex,
	setPageNext,
	setPagePrevious,
	submit = false,
	onSubmit = null,
}) => {
	const isActiveSlide = index === currentIndex;

	if (!isActiveSlide) {
		return null;
	}

	return (
		<div className="modal__slide">
			<div className="modal__container pl__l pr__l">
				{children}
				<div className="flex flex__space flex__align pt__m">
					{index > 0 && (
						<ButtonPrimary
							className="bg__3 flex__2 mr__m"
							onClick={setPagePrevious}
						>
							Previous
						</ButtonPrimary>
					)}
					{!submit && (
						<ButtonPrimary
							disabled={Boolean(required && !value)}
							className="bg__1 flex__2"
							onClick={setPageNext}
						>
							Next
						</ButtonPrimary>
					)}
					{submit && (
						<ButtonPrimary
							className="bg__1 flex__2"
							onClick={onSubmit}
						>
							Versturen
						</ButtonPrimary>
					)}
				</div>
			</div>
		</div>
	);
};

export default Slide;
