import React from "react";
import ReactDOM from "react-dom/client";

// Style import
import "./styles/index.scss";

// Main application entry
import App from './app';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
