import React, { useState } from "react";

// Components
import SlideLabel from "./slide_label";

const SlidesContainer =  ({ children }) => {
    const [currentIndex, setIndex] = useState(0);
    const [previousIndex, setPreviousIndex] = useState(0);

    const setPageNext = () => {
        if (currentIndex < children.length - 1) {
            if (children[currentIndex].props.required) {
                // - check if there is a value and if yes increate the index
                if (children[currentIndex].props.value) {
                    setPreviousIndex(currentIndex);
                    setIndex(currentIndex + 1);
                }
            } else {
                setPreviousIndex(currentIndex);
                setIndex(currentIndex + 1);
            }
        }
    };

    const setPagePrevious = () => {
        if (currentIndex > 0) {
            setPreviousIndex(currentIndex);
            setIndex(currentIndex - 1);
        }
    };

    return (
        <div>
            {React.Children.map(children, (child, index) =>
                React.cloneElement(child, {
                    key: index,
                    index,
                    currentIndex,
                    previousIndex,
                    setPageNext,
                    setPagePrevious
                })
            )}
            <div className="modal__dot_container">
                {React.Children.map(children, (_, index) => (
                    <SlideLabel
                        key={index}
                        index={index}
                        currentIndex={currentIndex}
                        itemsSize={children.length - 1}
                    />
                ))}
            </div>
        </div>
    );
};


export default SlidesContainer;