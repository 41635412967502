import { useEffect } from "react";

const ModalEventHandler = ({ toggle }) => {
    const onKeyUp = e => {
        if (e.keyCode === 27) {
            document.removeEventListener("keyup", onKeyUp);
            toggle();
        }
    };
    useEffect(() => {
        document.addEventListener("keyup", onKeyUp);
        return () => {
            document.removeEventListener("keyup", onKeyUp);
        };
    });
    return null;
};

export default ModalEventHandler;  