import React from "react";

const Reintegration =  ({ text }) => (
    <section className="container wrap flex flex__space">
        <div />
        <div className="container__content">
            <h1 className="header">{text.TITLE}</h1>
            <p className="pt__m">{text.DESCRIPTION[0]}</p>
            <p className="pt__m">{text.DESCRIPTION[1]}</p>
            <p className="pt__m">{text.DESCRIPTION[2]}</p>
        </div>
        <div />
    </section>
);

export default Reintegration;