import React, { useState } from "react";

// Components
import { IconSpin, IconImage } from "../../icons";

const GalleryImage =  ({ src }) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    if (error) {
        return (
            <div className="img__overlay flex flex__align p__box c__secondary">
                <div className="center full">
                    <IconImage className="icon icon__l" />
                    <div className="pt__s">
                        <h4>
                            <i>Oeps...</i>
                        </h4>
                        <span className="fs__xs">
                            De foto kon niet worden geladen
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    return [
        <img
            key="0"
            src={src}
            onLoad={() => setLoaded(true)}
            onError={() => setError(true)}
            className="img__hidden"
            alt="hidden loader"
        />,

        <div
            key="1"
            className={`img__overlay img__background img__loading ${loaded &&
                "img__loaded"}`}
            style={{ backgroundImage: `url(${src})` }}
        />,

        !loaded && (
            <div key="2" className="img__overlay flex flex__center">
                <IconSpin className="icon icon__l spin" />
            </div>
        )
    ];
};

export default GalleryImage;