export const OPTIONS_CONTACT = [
    "algemene informatie",
    "meer informatie over zorg",
    "meer informatie over een service",
    "partner worden",
    "interesse in samenwerking",
    "werken bij smart AF"
];

export const OPTIONS_DAY_PARTS = [
    "maakt niet uit",
    "morgen",
    "middag",
    "avond"
];
