import React, { useState } from "react";

//  Firebase
import { createAppointmentRequest } from "../../../core/contact_requests";

// Variables
import { OPTIONS_CONTACT, OPTIONS_DAY_PARTS } from "../../../variables/contact";

// Components
import { Input, Select } from "../../inputs/index";
import DatePicker from "../../inputs/datepicker";

import ModalSlide from "../slide";
import ModalSlides from "../slides_container";
import ModalHeader from "../slide_header";

import ModalLoader from "../modal_loader";
import ModalError from "../modal_error";
import ModalSuccess from "../modal_success";

const ModalAppointment = ({ toggle, text }) => {
    const [form, setForm] = useState({
        name: "",
        email: "",
        reason: OPTIONS_CONTACT[0],

        date: null,
        dayPart: OPTIONS_DAY_PARTS[0]
    });

    const [formStatus, setFormStatus] = useState({
        loading: false,
        error: false,
        success: false
    });

    const changeName = e =>
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });

    const changeReason = payload => setForm({ ...form, reason: payload });
    const changeDayPart = payload => setForm({ ...form, dayPart: payload });
    const changeDate = payload => setForm({ ...form, date: new Date(payload) });

    const submitCallback = async () => {
        setFormStatus({ ...formStatus, loading: true });
        const response = await createAppointmentRequest(form);

        if (response.success) {
            setFormStatus({ ...formStatus, loading: false, success: true });
        }
        if (response.error) {
            setFormStatus({ ...formStatus, loading: false, error: true });
        }
    };

    if (formStatus.loading) {
        return <ModalLoader />;
    }

    if (formStatus.error) {
        return <ModalError toggle={toggle} />;
    }

    if (formStatus.success) {
        return <ModalSuccess toggle={toggle} />;
    }

    return (
        <ModalSlides>
            <ModalSlide required value={form.name}>
                <ModalHeader text={text.NAME} />
                <Input
                    value={form.name}
                    name="name"
                    placeholder={text.NAME_PLACEHOLDER}
                    onChange={changeName}
                />
            </ModalSlide>

            <ModalSlide required value={form.email}>
                <ModalHeader text={text.EMAIL} />

                <Input
                    value={form.email}
                    name="email"
                    type="email"
                    placeholder={text.EMAIL_PLACEHOLDER}
                    onChange={changeName}
                />
            </ModalSlide>

            <ModalSlide>
                <ModalHeader text={text.REASON} />

                <Select
                    reason={form.reason}
                    options={OPTIONS_CONTACT}
                    changeReason={changeReason}
                />
            </ModalSlide>

            <ModalSlide equired value={form.date}>
                <ModalHeader text={text.DATE} />
                <div className="center">
                    <DatePicker
                        placeholderText={text.DATE_PLACEHOLDER}
                        className="input full"
                        selected={form.date}
                        onChange={changeDate}
                        dateFormat="MMMM d, yyyy"
                        minDate={new Date()}
                    />
                </div>
            </ModalSlide>

            <ModalSlide submit onSubmit={submitCallback}>
                <ModalHeader text={text.PART} />

                <Select
                    reason={form.dayPart}
                    options={OPTIONS_DAY_PARTS}
                    changeReason={changeDayPart}
                />
            </ModalSlide>
        </ModalSlides>
    );
};

export default ModalAppointment;