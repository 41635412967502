import React from "react";
import { useSpring, animated, config } from "react-spring";

const Link = ({ service, activeIndex, index, setIndex }) => (
    <div className="flex__2">
        <button
            type="button"
            className={`link ${activeIndex === index && "c__main"}`}
            onClick={() => setIndex(index)}
        >
            {service.TITLE}
        </button>
    </div>
);

const Tracker = ({ activeIndex }) => {
    const props = useSpring({
        to: {
            width: `${Math.random() * (8 - 2) + 2}rem`,
            left: `${(100 / 3) * activeIndex}%`
        },
        config: config.slow
    });

    return <animated.div className="line" style={props}></animated.div>;
};

export const Navigation = ({ activeIndex, setIndex, services }) => {
    return (
        <nav className="pt__l pb__m">
            <div className="flex flex__align flex__space flex__wrap">
                {services.map((item, index) => (
                    <Link
                        key={index}
                        service={item}
                        activeIndex={activeIndex}
                        index={index}
                        setIndex={setIndex}
                    />
                ))}
            </div>
            <div className="relative pt__s">
                <Tracker activeIndex={activeIndex} />
            </div>
        </nav>
    );
};
