import React from "react";

// Components
import { ButtonPrimary } from "../../buttons";

const Intro = ({ setModal, text}) => {
    return (
        <section className="intro__container wrap flex flex__align">
            <div className="container__webgl"></div>
            <div className="container__content relative">
                <h1 className="header">{text.TITLE}</h1>
                <p className="pt__m">{text.DESCRIPTION}</p>
                <div className="pt__m">
                    <ButtonPrimary
                        className="bg__1"
                        onClick={() =>
                            setModal({ open: true, appointment: true })
                        }
                    >
                        {text.BTN}
                    </ButtonPrimary>
                </div>
            </div>
        </section>
    );
}

export default Intro;
