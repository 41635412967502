import React, { useState } from "react";
import { useSpring, animated, config } from "react-spring";

export const ButtonPrimary =  ({
    disabled = false,
    children,
    className,
    onClick
}) => {
 

 

    return (
        <animated.button
            className={`btn btn__primary ${className}`}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
            <animated.div className="btn__background" />
        </animated.button>
    );
};

export const ButtonSecondary = ({ children, onClick }) => {
    const [on, toggleOn] = useState(false);

    const transition = useSpring({
        transform: on
            ? "scale(1) translateX(2.75rem)"
            : "scale(0) translateX(-6rem)",

        config: config.wobbly,
        duration: 500
    });

    const color = useSpring({
        color: on ? "#44e0a6" : "#ffffff"
    });

    return (
        <animated.button
            style={color}
            className="btn btn__secondary"
            onClick={onClick}
            onMouseEnter={() => toggleOn(true)}
            onMouseLeave={() => toggleOn(false)}
        >
            {children}

            <div className="line_container flex flex__align">
                <span className="line" />
                <animated.span style={transition} className="dot dot__hidden" />
            </div>
        </animated.button>
    );
};
