import React, { useState } from "react";

// Components
import Navigation from "../components/navigation/main_navigation";
import Intro from "../components/page_components/intro";
import Services from "../components/page_components/services";
import Reintegration from "../components/page_components/reintegration";
import Corporation from "../components/page_components/corporation";
import Contact from "../components/page_components/contact";
import Gallery from "../components/page_components/gallery";
import ModalContact from "../components/modals/modal_contact";
import Footer from "../components/footer";
import applicationText from "../variables/text";


const Home = ({ language, setLanguage }) => {

    const [contactModal, setModal] = useState({
        open: false,
        appointment: false
    });

    let text = language ? applicationText.NL : applicationText.ENG;

    return (
        <main>
            <Navigation language={language} setLanguage={setLanguage} />
            <Intro setModal={setModal} text={text.INTRO} />
            <Services text={text.SERVICES} />
            <Reintegration text={text.REINTEGRATION} />
            <Corporation text={text.CORPORATION} />
            <Gallery />
            <Contact setModal={setModal} text={text.CONTACT} />
            <Footer text={text.FOOTER} />
            <ModalContact
                {...contactModal}
                toggle={setModal}
                text={text.FORM}
            />
        </main>
    );
};

export default Home;
