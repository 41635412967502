import { db } from "../firebase";
import { addDoc, collection } from "firebase/firestore";
import { createErrorLog } from "../helpers";

export const createCallbackRequest = ({ name, reason, phone, dayPart, date }) =>
	addDoc(collection(db, "callbacks"), {
		name,
		phone,
		reason,
		date,
		dayPart,
		created_at: new Date(),
	})
		.then(() => ({ success: true }))
		.catch((error) => createErrorLog(error));

export const createAppointmentRequest = ({
	name,
	email,
	reason,
	date,
	dayPart,
}) =>
	addDoc(collection(db, "appointments"), {
		name,
		reason,
		email,
		dayPart,
		date,
		created_at: new Date(),
	})
		.then(() => ({ success: true }))
		.catch((error) => createErrorLog(error));
