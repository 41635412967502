import React from "react";

//  Components
import ModalEventHandler from "./modal_event_handler";
import ModalNavigation from "./modal_navigation";

const Modal = ({ open, children, toggle, center }) => {
	const handleBackgroundClick = (e) => {
		if (e.target === e.currentTarget) toggle();
	};

	if (!open) {
		return null;
	}

	return (
		<div className="modal">
			<ModalNavigation toggle={toggle} />
			<div
				className={center ? "modal__center" : "modal__scroll"}
				onClick={handleBackgroundClick}
			>
				{children}
			</div>
			<ModalEventHandler toggle={toggle} />
		</div>
	);
};

export default Modal;
