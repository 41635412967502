import React, { useState } from "react";
// Variables
// import { services } from "../../../variables/services";

// Components
import { Navigation } from "../../navigation/services_navigation";
import { Services } from "./service";

const Service = ({ text }) => {
    const [activeIndex, setIndex] = useState(0);

    return (
        <section className="container wrap">
            <div className="container__content">
                <h1 className="header">{text.TITLE}</h1>
                <p className="pt__m">{text.DESCRIPTION}</p>
            </div>
            <Navigation
                className="pt__l"
                activeIndex={activeIndex}
                setIndex={setIndex}
                services={text.SERVICES}
            />
            <Services
                activeIndex={activeIndex}
                setIndex={setIndex}
                services={text.SERVICES}
            />
        </section>
    );
};

export default Service; 
