import { useState } from "react";

import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";

// Components
import Home from "./pages/home";



const App = () => {
    const browserLanguageSetting = window.navigator.userLanguage || window.navigator.language;

    const [language, setLanguage] = useState(
        /nl/i.test(browserLanguageSetting)
    );


    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home language={language} setLanguage={setLanguage} />}/>
            </Routes>
        </BrowserRouter>
    );
}


export default App;