import React from "react";
import { useSpring, animated, config } from "react-spring";

const ModalSlide =  ({ index, currentIndex, itemsSize }) => {
    const isActiveSlide = index === currentIndex;
    const transition = useSpring({
        config: config.slow,
        transform: isActiveSlide ? "scale(1.3)" : "scale(1)",
        borderColor: isActiveSlide ? "#44e0a6" : "rgba(19, 28, 65,1)"
    });

    const transitionLine = useSpring({
        config: config.slow,
        paddingBottom: isActiveSlide ? "32px" : "8px",
        backgroundColor: isActiveSlide ? "#44e0a6" : "rgba(19, 28, 65,1)"
    });

    return (
        <div className="mb__s">
            <animated.div className="modal__dot" style={transition} />
            {itemsSize !== index && (
                <animated.div className="modal__line" style={transitionLine} />
            )}
        </div>
    );
};

export default ModalSlide;
