import React, { useEffect, useState } from "react";
import { useSpring, animated, config } from "react-spring";
import { NavLink } from "react-router-dom";

// Variables
import { routes } from "../../variables/routes";

const Logo = props => {
    const { color } = useSpring({
        color: props.open ? "#44e0a6" : "#ffffff",
        config: { duration: 500 }
    });

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 712.0925 104.7201"
            stroke="currentColor"
            fill="currentColor"
            {...props}
        >
            <path d="M458.1478.0006h-78.54a2.618,2.618,0,0,0-2.618,2.618V18.3265a2.618,2.618,0,0,0,2.618,2.618H408.406v81.1576a2.618,2.618,0,0,0,2.618,2.618h15.7079a2.618,2.618,0,0,0,2.618-2.618V20.9445h28.7979a2.618,2.618,0,0,0,2.618-2.618V2.6186A2.618,2.618,0,0,0,458.1478.0006Z" />
            <animated.path
                style={{ fill: color, stroke: color }}
                d="M712.0925,18.3265V2.6186a2.618,2.618,0,0,0-2.618-2.618h-78.54a2.618,2.618,0,0,0-2.618,2.618v99.4835a2.618,2.618,0,0,0,2.618,2.618h15.708a2.618,2.618,0,0,0,2.6179-2.618v-39.27h39.27a2.618,2.618,0,0,0,2.618-2.618V44.5064a2.618,2.618,0,0,0-2.618-2.618h-39.27V20.9445h60.2137A2.618,2.618,0,0,0,712.0925,18.3265Z"
            />
            <path d="M347.3468,104.72h15.3046a2.618,2.618,0,0,0,2.4307-3.59L352.7088,70.1964a26.17,26.17,0,0,0,13.8091-23.0723V26.18a26.18,26.18,0,0,0-26.18-26.18L285.36,0a2.618,2.618,0,0,0-2.618,2.618l0,99.4841a2.618,2.618,0,0,0,2.618,2.618h15.708a2.618,2.618,0,0,0,2.6179-2.618V73.3038h29.3214l11.9082,29.7705A2.6178,2.6178,0,0,0,347.3468,104.72Zm-43.66-83.7765,36.6519-.0007a5.236,5.236,0,0,1,5.2359,5.236V47.1245A5.2356,5.2356,0,0,1,340.34,52.36H328.8192l-25.1327,0Z" />
            <path d="M156.3992,1.5873,136.1354,48.87,115.8715,1.5873A2.618,2.618,0,0,0,113.4652.0006h-16.6a2.618,2.618,0,0,0-2.618,2.618v99.4835a2.618,2.618,0,0,0,2.618,2.618h15.7079a2.618,2.618,0,0,0,2.618-2.618V48.87l9.7922,22.8472a2.618,2.618,0,0,0,2.4063,1.5866l17.4909,0a2.6181,2.6181,0,0,0,2.4063-1.5867L157.0793,48.87v53.2323a2.618,2.618,0,0,0,2.618,2.618h15.7079a2.618,2.618,0,0,0,2.618-2.618V2.6186a2.618,2.618,0,0,0-2.618-2.618h-16.6A2.6181,2.6181,0,0,0,156.3992,1.5873Z" />
            <path d="M253.2747,104.72H268.752a2.618,2.618,0,0,0,2.5076-3.37L241.4146,1.8663A2.6181,2.6181,0,0,0,238.907.0006H221.8588a2.6182,2.6182,0,0,0-2.5076,1.8657L189.5062,101.35a2.618,2.618,0,0,0,2.5076,3.37h15.4773a2.6179,2.6179,0,0,0,2.5076-1.8657l2.5821-8.6069H248.185l2.5821,8.6069A2.6179,2.6179,0,0,0,253.2747,104.72ZM218.864,73.3036l11.5189-38.3965,11.5189,38.3965Z" />
            <animated.path
                style={{ fill: color, stroke: color }}
                d="M567.4331,104.72h15.4774a2.618,2.618,0,0,0,2.5076-3.37L555.573,1.8663A2.618,2.618,0,0,0,553.0654.0006H536.0173A2.618,2.618,0,0,0,533.51,1.8663L503.6646,101.35a2.618,2.618,0,0,0,2.5076,3.37H521.65a2.618,2.618,0,0,0,2.5076-1.8657l2.582-8.6069h35.6043l2.582,8.6069A2.618,2.618,0,0,0,567.4331,104.72ZM533.0225,73.3036l11.5189-38.3965L556.06,73.3036Z"
            />
            <path d="M41.8878,104.72c-27.14,0-37.9228-13.7876-40.9359-24.7472A5.2522,5.2522,0,0,1,6,73.3043H17.0066a5.2254,5.2254,0,0,1,4.6657,3.1129c1.5585,3.2621,6.2969,7.359,20.2155,7.359,19.2719,0,20.949-7.8544,20.949-10.7736,0-2.3168-1.6771-10.17-20.949-10.17C8.7539,62.8323,0,42.2821,0,31.4165S8.7539.0006,41.8878.0006c27.14,0,37.9228,13.7876,40.9359,24.7472a5.2523,5.2523,0,0,1-5.0485,6.6687H66.77a5.2262,5.2262,0,0,1-4.6662-3.1134c-1.5585-3.2622-6.2969-7.3586-20.2155-7.3586-19.2719,0-20.949,7.8535-20.949,10.7737,0,2.3158,1.6771,10.17,20.949,10.17,33.1339,0,41.8878,20.55,41.8878,31.4159S75.0217,104.72,41.8878,104.72Z" />
            <animated.rect
                x="596.9011"
                y="83.7762"
                width="20.9439"
                height="20.9439"
                rx="2.618"
                style={{ fill: color, stroke: color }}
            />
            <animated.rect
                x="691.1486"
                y="83.7757"
                width="20.9439"
                height="20.9439"
                rx="2.618"
                style={{ fill: color, stroke: color }}
            />
        </svg>
    );
};

const Settings = ({ language }) => {
    const props = useSpring({
        transform: language ? "rotate(180deg)" : "rotate(0deg)",
        config: config.wobbly
    });

    return (
        <animated.svg
            style={props}
            className="icon icon__sm"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            stroke="currentColor"
        >
            <g strokeLinecap="round" strokeLinejoin="round">
                <polyline points="23 4 23 10 17 10"></polyline>
                <polyline points="1 20 1 14 7 14"></polyline>
                <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
            </g>
        </animated.svg>
    );
};

const Navigation = ({ language, setLanguage }) => {
    let lastScrollY = 0;
    let ticking = false;

    const [open, setMenuOpen] = useState(false);

    const { padding, backgroundColor, borderColor } = useSpring({
        padding: open ? "1.2rem" : "2.4rem",
        backgroundColor: open ? "rgba(19, 28, 65, 1)" : "rgba(19, 28, 65, 0)",
        borderColor: !open ? "rgba(2, 6, 21, 0)" : "rgba(2, 6, 21, 0.2)"
    });

    function requestTick() {
        if (!ticking) {
            requestAnimationFrame(update);
            ticking = true;
        }
    }

    function onScroll() {
        lastScrollY = window.scrollY;
        requestTick();
    }

    function update() {
        if (lastScrollY > 80) {
            // check if the menu is open
            if (!open) {
                setMenuOpen(true);
            }
        } else {
            if (open) {
                setMenuOpen(false);
            }
        }
        ticking = false;
    }

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    });
    return (
        <animated.header
            className="navigation flex flex__align flex__space wrap"
            style={{
                paddingTop: padding,
                paddingBottom: padding,
                backgroundColor: backgroundColor,
                borderColor: borderColor
            }}
        >
            <NavLink to={routes.ROOT}>
                <Logo className="flex logo" open={open} />
            </NavLink>
            <button
                className="btn__hover flex flex__align"
                onClick={() => setLanguage(!language)}
            >
                <span className="mr__s">{language ? "NL" : "ENG"}</span>
                <Settings language={language} />
            </button>
        </animated.header>
    );
}

export default Navigation;