import React from "react";
import { useSpring, animated, config } from "react-spring";

// Variables
// import { ButtonPrimary } from "../../buttons";
import Image from "../gallery/image";

const Service = ({ service, index, activeIndex }) => {
    const isActive = activeIndex === index;

    const props = useSpring({
        transform: isActive ? "scale(1)" : "scale(0.9)",
        config: config.stiff
    });

    const image = `${process.env.PUBLIC_URL}${service.IMAGE}`;

    return (
        isActive && (
            <div className="flex flex__service">
                <div className="flex__2">
                    <animated.div style={props} className="item__service">
                        {isActive && <Image src={image} />}
                    </animated.div>
                </div>
                <div className="flex__2 pt__m">
                    <h2>{service.TITLE}</h2>
                    <p className="pt__m pb__m">{service.DESCRIPTION}</p>
                </div>
            </div>
        )
    );
};

export const Services = ({ activeIndex, services }) => {
    return (
        <div className="pt__m" style={{ minHeight: "50vh" }}>
            {services.map((item, index) => (
                <Service
                    key={index}
                    src={item}
                    service={item}
                    index={index}
                    activeIndex={activeIndex}
                />
            ))}
        </div>
    );
};
