import React from "react";

import Image from "../page_components/gallery/image";
import { ButtonPrimary } from "../buttons";

const ModalError =  ({ toggle }) => (
    <div className="modal__slide">
        <Image src={`${process.env.PUBLIC_URL}/assets/gifs/error.gif`} />
        <div className="modal__slide">
            <div className="modal__container c__white">
                <h1 className="center">
                    Er is een fout gemaakt bij het aamaken van de afspraak,
                    sorry....
                </h1>
                <ButtonPrimary className="mt__m bg__1 full" onClick={toggle}>
                    Sluiten
                </ButtonPrimary>
            </div>
        </div>
    </div>
);

export default ModalError;