import React from "react";

// Component
import { Svg } from "./svg";

export const Logo = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 712.0925 104.7201"
			stroke="currentColor"
			fill="currentColor"
			{...props}
		>
			<path d="M458.1478.0006h-78.54a2.618,2.618,0,0,0-2.618,2.618V18.3265a2.618,2.618,0,0,0,2.618,2.618H408.406v81.1576a2.618,2.618,0,0,0,2.618,2.618h15.7079a2.618,2.618,0,0,0,2.618-2.618V20.9445h28.7979a2.618,2.618,0,0,0,2.618-2.618V2.6186A2.618,2.618,0,0,0,458.1478.0006Z" />
			<path d="M712.0925,18.3265V2.6186a2.618,2.618,0,0,0-2.618-2.618h-78.54a2.618,2.618,0,0,0-2.618,2.618v99.4835a2.618,2.618,0,0,0,2.618,2.618h15.708a2.618,2.618,0,0,0,2.6179-2.618v-39.27h39.27a2.618,2.618,0,0,0,2.618-2.618V44.5064a2.618,2.618,0,0,0-2.618-2.618h-39.27V20.9445h60.2137A2.618,2.618,0,0,0,712.0925,18.3265Z" />
			<path d="M347.3468,104.72h15.3046a2.618,2.618,0,0,0,2.4307-3.59L352.7088,70.1964a26.17,26.17,0,0,0,13.8091-23.0723V26.18a26.18,26.18,0,0,0-26.18-26.18L285.36,0a2.618,2.618,0,0,0-2.618,2.618l0,99.4841a2.618,2.618,0,0,0,2.618,2.618h15.708a2.618,2.618,0,0,0,2.6179-2.618V73.3038h29.3214l11.9082,29.7705A2.6178,2.6178,0,0,0,347.3468,104.72Zm-43.66-83.7765,36.6519-.0007a5.236,5.236,0,0,1,5.2359,5.236V47.1245A5.2356,5.2356,0,0,1,340.34,52.36H328.8192l-25.1327,0Z" />
			<path d="M156.3992,1.5873,136.1354,48.87,115.8715,1.5873A2.618,2.618,0,0,0,113.4652.0006h-16.6a2.618,2.618,0,0,0-2.618,2.618v99.4835a2.618,2.618,0,0,0,2.618,2.618h15.7079a2.618,2.618,0,0,0,2.618-2.618V48.87l9.7922,22.8472a2.618,2.618,0,0,0,2.4063,1.5866l17.4909,0a2.6181,2.6181,0,0,0,2.4063-1.5867L157.0793,48.87v53.2323a2.618,2.618,0,0,0,2.618,2.618h15.7079a2.618,2.618,0,0,0,2.618-2.618V2.6186a2.618,2.618,0,0,0-2.618-2.618h-16.6A2.6181,2.6181,0,0,0,156.3992,1.5873Z" />
			<path d="M253.2747,104.72H268.752a2.618,2.618,0,0,0,2.5076-3.37L241.4146,1.8663A2.6181,2.6181,0,0,0,238.907.0006H221.8588a2.6182,2.6182,0,0,0-2.5076,1.8657L189.5062,101.35a2.618,2.618,0,0,0,2.5076,3.37h15.4773a2.6179,2.6179,0,0,0,2.5076-1.8657l2.5821-8.6069H248.185l2.5821,8.6069A2.6179,2.6179,0,0,0,253.2747,104.72ZM218.864,73.3036l11.5189-38.3965,11.5189,38.3965Z" />
			<path d="M567.4331,104.72h15.4774a2.618,2.618,0,0,0,2.5076-3.37L555.573,1.8663A2.618,2.618,0,0,0,553.0654.0006H536.0173A2.618,2.618,0,0,0,533.51,1.8663L503.6646,101.35a2.618,2.618,0,0,0,2.5076,3.37H521.65a2.618,2.618,0,0,0,2.5076-1.8657l2.582-8.6069h35.6043l2.582,8.6069A2.618,2.618,0,0,0,567.4331,104.72ZM533.0225,73.3036l11.5189-38.3965L556.06,73.3036Z" />
			<path d="M41.8878,104.72c-27.14,0-37.9228-13.7876-40.9359-24.7472A5.2522,5.2522,0,0,1,6,73.3043H17.0066a5.2254,5.2254,0,0,1,4.6657,3.1129c1.5585,3.2621,6.2969,7.359,20.2155,7.359,19.2719,0,20.949-7.8544,20.949-10.7736,0-2.3168-1.6771-10.17-20.949-10.17C8.7539,62.8323,0,42.2821,0,31.4165S8.7539.0006,41.8878.0006c27.14,0,37.9228,13.7876,40.9359,24.7472a5.2523,5.2523,0,0,1-5.0485,6.6687H66.77a5.2262,5.2262,0,0,1-4.6662-3.1134c-1.5585-3.2622-6.2969-7.3586-20.2155-7.3586-19.2719,0-20.949,7.8535-20.949,10.7737,0,2.3158,1.6771,10.17,20.949,10.17,33.1339,0,41.8878,20.55,41.8878,31.4159S75.0217,104.72,41.8878,104.72Z" />
			<rect
				x="596.9011"
				y="83.7762"
				width="20.9439"
				height="20.9439"
				rx="2.618"
			/>
			<rect
				x="691.1486"
				y="83.7757"
				width="20.9439"
				height="20.9439"
				rx="2.618"
			/>
		</svg>
	);
};

export const IconLeft = (props) => (
	<Svg {...props}>
		<circle cx="12" cy="12" r="10"></circle>
		<polyline points="12 8 8 12 12 16"></polyline>
		<line x1="16" y1="12" x2="8" y2="12"></line>
	</Svg>
);

export const IconRight = (props) => (
	<Svg {...props}>
		<circle cx="12" cy="12" r="10"></circle>
		<polyline points="12 16 16 12 12 8"></polyline>
		<line x1="8" y1="12" x2="16" y2="12"></line>
	</Svg>
);

export const IconError = (props) => (
	<Svg {...props}>
		<circle cx="12" cy="12" r="10" />
		<line x1="15" y1="9" x2="9" y2="15" />
		<line x1="9" y1="9" x2="15" y2="15" />
	</Svg>
);

export const IconLocation = (props) => (
	<Svg {...props}>
		<path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
		<circle cx="12" cy="10" r="3" />
	</Svg>
);

export const IconMail = (props) => (
	<Svg {...props}>
		<path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
		<polyline points="22,6 12,13 2,6" />
	</Svg>
);

export const IconPhone = (props) => (
	<Svg {...props}>
		<path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
	</Svg>
);

export const IconSpin = (props) => (
	<Svg {...props}>
		<line x1="12" y1="2" x2="12" y2="6" />
		<line x1="12" y1="18" x2="12" y2="22" />
		<line x1="4.93" y1="4.93" x2="7.76" y2="7.76" />
		<line x1="16.24" y1="16.24" x2="19.07" y2="19.07" />
		<line x1="2" y1="12" x2="6" y2="12" />
		<line x1="18" y1="12" x2="22" y2="12" />
		<line x1="4.93" y1="19.07" x2="7.76" y2="16.24" />
		<line x1="16.24" y1="7.76" x2="19.07" y2="4.93" />
	</Svg>
);
export const IconImage = (props) => (
	<Svg {...props}>
		<rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
		<circle cx="8.5" cy="8.5" r="1.5" />{" "}
		<polyline points="21 15 16 10 5 21" />
	</Svg>
);

export const IconSelect = (props) => (
	<Svg {...props}>
		<polyline points="7 13 12 18 17 13" />
		<polyline points="7 6 12 11 17 6" />
	</Svg>
);

export const IconSettings = (props) => (
	<Svg {...props}>
		<line x1="4" y1="21" x2="4" y2="14"></line>
		<line x1="4" y1="10" x2="4" y2="3"></line>
		<line x1="12" y1="21" x2="12" y2="12"></line>
		<line x1="12" y1="8" x2="12" y2="3"></line>
		<line x1="20" y1="21" x2="20" y2="16"></line>
		<line x1="20" y1="12" x2="20" y2="3"></line>
		<line x1="1" y1="14" x2="7" y2="14"></line>
		<line x1="9" y1="8" x2="15" y2="8"></line>
		<line x1="17" y1="16" x2="23" y2="16"></line>
	</Svg>
);

export const IconDocument = (props) => (
	<Svg {...props}>
		<path
			stroke-linecap="round"
			stroke-linejoin="round"
			d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
		/>
	</Svg>
);
