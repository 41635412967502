import React, { useState } from "react";
import { useSpring, animated } from "react-spring";

// Components
import Card from "./card";
import { IconLeft, IconRight } from "../../icons";

const images = [
    `${process.env.PUBLIC_URL}/assets/images/DSCF3049.jpg`,
    `${process.env.PUBLIC_URL}/assets/images/DSCF3051.jpg`,
    `${process.env.PUBLIC_URL}/assets/images/DSCF3052.jpg`,
    `${process.env.PUBLIC_URL}/assets/images/DSCF3055.jpg`,
    `${process.env.PUBLIC_URL}/assets/images/DSCF3057.jpg`,
    `${process.env.PUBLIC_URL}/assets/images/DSCF3060.jpg`,
    `${process.env.PUBLIC_URL}/assets/images/DSCF3081.jpg`,
    `${process.env.PUBLIC_URL}/assets/images/DSCF3084.jpg`,
    `${process.env.PUBLIC_URL}/assets/images/DSCF3086.jpg`,
    `${process.env.PUBLIC_URL}/assets/images/DSCF3088.jpg`
];

const Gallery = () => {
    const S = images.length;
    const W = window.innerWidth;

    const slideWidth = (W / 100) * 80 + 25;

    const [activeIndex, setIndex] = useState(0);
    const props = useSpring({
        transform: `translate3d(-${activeIndex * slideWidth}px,0,0)`
    });

    const nextSlide = () => {
        if (activeIndex === S - 1) {
            return;
        }
        const newIndex = activeIndex + 1;
        return setIndex(newIndex);
    };

    const prevSlide = () => {
        if (!activeIndex) return;
        const newIndex = activeIndex - 1;
        return setIndex(newIndex);
    };

    return (
        <div>
            <div className="slider">
                <animated.div style={props} className="slider__container">
                    {images.map((item, index) => (
                        <Card
                            key={item}
                            src={item}
                            index={index}
                            activeIndex={activeIndex}
                        />
                    ))}
                </animated.div>
            </div>
            <div className="wrap pt__m flex flex__align">
                <button
                    className="flex flex__align btn__hover"
                    onClick={() => prevSlide()}
                >
                    <IconLeft className="icon icon__m" />
                    <span className="ml__s">previous</span>
                </button>
                <button
                    className="flex flex__align ml__s btn__hover"
                    onClick={() => nextSlide()}
                >
                    <span>next</span>
                    <IconRight className="icon icon__m ml__s" />
                </button>
            </div>
        </div>
    );
};

export default Gallery;