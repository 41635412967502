import { db } from "./firebase";

export const createErrorLog = async error => {
    await db
        .collection("contact_error")
        .add({
            error_name: error.name || "undefined",
            error_message: error.message || "undefined",
            created_at: new Date()
        })
        .catch(error => ({ error }));

    return { error: true };
};
