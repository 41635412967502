import React from "react";

import Image from "../page_components/gallery/image";
import { ButtonPrimary } from "../buttons";

const ModalSuccess  = ({ toggle }) => (
    <div className="modal__slide">
        <Image src={`${process.env.PUBLIC_URL}/assets/gifs/thank_you.gif`} />
        <div className="modal__slide">
            <div className="modal__container c__white">
                <h1 className="center">
                    Bedankt voor het maken van een afspraak, we bellen je snel
                    terug
                </h1>
                <ButtonPrimary className="mt__m bg__1 full" onClick={toggle}>
                    Sluiten
                </ButtonPrimary>
            </div>
        </div>
    </div>
);

export default ModalSuccess;
