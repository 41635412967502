const translations =  {
    NL: {
        INTRO: {
            TITLE: "The Social Innovation Lab",
            DESCRIPTION:
                "Een broedplaats voor IT talent die een afstand tot de arbeidsmarkt ervaren: welkom bij Smart A.F.!  Waar re-integratie wordt gecombineerd met het verleggen van grenzen in de digitale wereld.",
            BTN: "Afspraak maken"
        },
        SERVICES: {
            TITLE: "Services",
            DESCRIPTION:
                "Bij Smart A.F. werken we met hoogbegaafde IT’ers die met de juiste begeleiding tot ongelofelijke prestaties in staat zijn. Door met ons samen te werken krijgen deze talenten de mogelijkheid om te participeren en een bijdrage te leveren aan de maatschappij.",

            SERVICES: [
                {
                    TITLE: "Hulp op Afstand",
                    DESCRIPTION:
                        "De digitale kant van bedrijfsvoering wordt steeds belangrijker maar de complexiteit neemt alleen maar toe. Hulp op Afstand helpt met deze complexiteit en is een service voor het verbeteren van de IT infrastructuur. Wij onderhouden structureel de prestatie, problemen en veiligheid van de hardware, software en netwerk. Samen kijken we wat jouw organisatie nodig heeft zodat we maximale impact realiseren zonder overbodige functionaliteiten.",
                    BTN: "Afspraak maken",
                    IMAGE: "/assets/images/DSCF3046.jpg"
                },
                {
                    TITLE: "Research & Development",
                    DESCRIPTION:
                        "Een geweldig idee voor een digitaal concept maar niet bekend met de IT ontwikkeling? Smart A.F. onderzoekt wat er mogelijk is, prototyped en test de haalbaarheid en schaalbaarheid van het concept. Wij brengen ideeën tot leven in de vorm van digitale applicaties!",
                    BTN: "Afspraak maken",
                    IMAGE: "/assets/images/DSCF3038.jpg"
                },
                {
                    TITLE: "Outsourcing",
                    DESCRIPTION:
                        "Een overload aan werk binnen je organisatie of een extra handje nodig voor een grote opdracht? Smart A.F. ondersteunt met developers en testers die jouw workload verzachten. Wij helpen met programmeren, cybersecurity en het testen van applicaties. Waarom outsourcen naar het buitenland als je talenten in Nederland weer een plek kunt geven in de maatschappij.",
                    BTN: "Afspraak maken",
                    IMAGE: "/assets/images/DSCF3060.jpg"
                }
            ]
        },
        REINTEGRATION: {
            TITLE: "Re-integratie",
            DESCRIPTION: [
                "Binnen het re-integratie traject van Smart A.F. krijgen cliënten naast professionele begeleiding de kans om te werken aan opdrachten die van echte meerwaarde zijn. Door weer een doel in het werkleven te krijgen willen wij de intrinsieke motivatie raken die zo belangrijk is voor het re-integreren binnen het werkveld.",
                "Werk (vinden) met een zorgindicatie kan een lastige opgave zijn. Waar sommige bedrijven problemen zien in het werken met mensen met een afstand tot de arbeidsmarkt zien wij juist enorme kansen. Smart A.F. creëert een comfortabele werkplek waar de cliënten zich veilig voelen, ruimte hebben om zich te ontwikkelen en waar drempels bespreekbaar worden gemaakt.",
                "Binnen Smart A.F. kunnen de cliënten werken aan IT opdrachten met impact op onze maatschappij. Opdrachten die uitdagend zijn, hun vertrouwen geeft en waar ze zichzelf professioneel kunnen ontwikkelen."
            ]
        },
        CORPORATION: {
            TITLE: "Onze corporatie",
            DESCRIPTION: [
                "Smart A.F. is ontstaan uit een samenwerking tussen het servicedesign bedrijf TriMotion en de IT dagbesteding Piekfyn. We zien dat het bestaande sociale zorgsysteem onvoldoende in staat is meetbare impact te leveren op het verbeteren van het toekomstperspectief van de mensen die hierop aangewezen zijn. Het is onze diepste overtuiging dat hiervoor radicale verbeteringen in de aanpak en middelen voor deze sector nodig zijn.",
                "Daarom hebben we een R&D werkplaats opgericht waar hoogbegaafde IT-ers met een zorgindicatie en zij-instromers werken aan (technische) innovaties en Serious Gaming voor maatschappelijke vraagstukken. We zijn ervan overtuigd dat cliënten die hun talenten zinvol en zinrijk kunnen inzetten zich persoonlijk ontwikkelen tot volwaardige deelnemers aan de samenleving. Deze volwaardige deelneming uit zich uiteindelijke in deelneming binnen de coöperatie Smart A.F. met gedeeld stemrecht en winstuitkering voor de werknemers (ex cliënten)."
            ]
        },
        CONTACT: {
            TITLE: "Ontdekken hoe onze Social Innovation Lab jou kan helpen?",
            BTN_APPOINTMENT: "Afspraak maken",
            BTN_CALLBACK: "Terugbel verzoek"
        },
        FOOTER: {
            TITLE: "Missie",
            DESCRIPTION:
                "IT Professionals met een afstand tot de arbeidsmarkt weer bij laten dragen aan de maatschappij door een veilige omgeving te creëren waarin technische innovatie en zorg samen komen."
        },

        FORM: {
            APPOINTMENT: {
                NAME: "Wat is je naam?",
                NAME_PLACEHOLDER: "Type hier je naam",

                EMAIL: "Wat is je email adres?",
                EMAIL_PLACEHOLDER: "Email adres waarop we je kunnen bereiken",

                REASON: "Reden voor de afspraak",

                DATE: "Welke datum komt goed uit?",
                DATE_PLACEHOLDER: "Zo snel mogelijk",

                PART: "Welk dagdeel komt het beste uit?"
            },
            CALLBACK: {
                NAME: "Wat is je naam?",
                NAME_PLACEHOLDER: "Type hier je naam",

                NUMBER: "Wat is je telefoonnummer?",
                NUMBER_PLACEHOLDER: "Nummer waarop we je kunnen bellen",

                REASON: "Reden voor terugbellen",

                DATE: "Welke datum komt goed uit?",
                DATE_PLACEHOLDER: "Zo snel mogelijk",

                PART: "Welk dagdeel komt het beste uit?"
            }
        }
    },
    ENG: {
        INTRO: {
            TITLE: "The Social Innovation Lab",
            DESCRIPTION:
                "A breeding ground for IT talent who experience a distance from the labour market: welcome to Smart A.F.! Where reintegration is combined with pushing boundaries within the digital world.",
            BTN: "Make an appointment"
        },
        SERVICES: {
            TITLE: "Services",
            DESCRIPTION:
                "At Smart A.F. we work with highly intelligent IT professionals who are capable of developing incredible things with the right guidance. By working with us, these talents are given the opportunity to participate and to contribute back to society.",
            SERVICES: [
                {
                    TITLE: "Remote Assistance",
                    DESCRIPTION:
                        "Digital systems within organisations are becoming more important every day, but are getting more and more complex. Remote assistance is a service for the improvement of the IT infrastructure and helps to overcome this complexity. We maintain the performance, problems and safety of the hardware, software and network. By analysing the needs of your company together we can realise maximum impact without unnecessary functionalities.",
                    BTN: "Make an appointment",
                    IMAGE: "/assets/images/DSCF3046.jpg"
                },
                {
                    TITLE: "Research & Development",
                    DESCRIPTION:
                        "A great idea for a digital concept but not familiar with actual IT development? Smart A.F. researches the possibilities, prototypes and tests the feasibility and scalability of the concept. We bring ideas to life in the form of digital applications.",
                    BTN: "Make an appointment",
                    IMAGE: "/assets/images/DSCF3038.jpg"
                },
                {
                    TITLE: "Outsourcing",
                    DESCRIPTION:
                        "Got an overload of work or needing an extra hand for a big project? Smart A.F. can support you by providing developers and testers who can alleviate your workload. We help with programming, cybersecurity and testing of applications. Why outsource abroad when you can give talents in the Netherlands a place in society again?",
                    BTN: "Make an appointment",
                    IMAGE: "/assets/images/DSCF3060.jpg"
                }
            ]
        },
        REINTEGRATION: {
            TITLE: "Reintegration",
            DESCRIPTION: [
                "The reintegration program of Smart A.F. gives clients professional support and an opportunity to work on projects with real societal  impact. By recovering the purpose in work we want to touch on intrinsic motivation, which is of significant importance for reintegration within the workforce.",
                "(Finding) work with a care indication can be a hard task to accomplish. Where some companies might see problems working with people with a distance to the labour market, we see shared opportunities. At Smart A.F. we create a save environment where clients can feel comfortable, have room to develop and issues can be openly discussed.",
                "At Smart A.F. clients can work on IT projects which make a difference for society. Projects which are challenging, give them confidence and where they can develop professionally."
            ]
        },
        CORPORATION: {
            TITLE: "Our corporation",
            DESCRIPTION: [
                "Smart A.F. emerged from a collaboration between the service design company TriMotion and the IT reintegration Piekfyn. We see that the existing social care system is insufficiently capable of delivering measurable impact on  improving the future prospects for the designated clients. It is our deepest belief that this requires radical improvements in the approach and resources for this sector.",
                "That’s why we founded an R&D Lab where Highly intelligent IT professionals with a care indication and side entrants can work together on innovations and Serious Games for the issues within society. We are convinced that clients who use their talents in a meaningful way can develop themselves to become full participants of society. This participation can result in admission to the corporation Smart A.F. with partial voting rights and a share in the employee profit distribution."
            ]
        },
        CONTACT: {
            TITLE:
                "Want to discover how our Social Innovation Lab can help you?",
            BTN_APPOINTMENT: "Make an appointment",
            BTN_CALLBACK: "Receive a callback"
        },
        FOOTER: {
            TITLE: "Mission",
            DESCRIPTION:
                "Helping IT Professionals with a distance from the labour market  to participate and contribute back to society again by creating a save environment where Tech innovation and care come together."
        },

        FORM: {
            APPOINTMENT: {
                NAME: "What's your name?",
                NAME_PLACEHOLDER: "Type your name here",

                EMAIL: "What is your email address?",
                EMAIL_PLACEHOLDER: "Email address where we can reach you",

                REASON: "Reason for the appointment",

                DATE: "Which date suits you well?",
                DATE_PLACEHOLDER: "As quickly as possible",

                PART: "Which part of the day works best?"
            },
            CALLBACK: {
                NAME: "What's your name?",
                NAME_PLACEHOLDER: "Type your name here",

                NUMBER: "What's your phonenumber?",
                NUMBER_PLACEHOLDER: "Number where we can call you",

                REASON: "Reden voor callback",

                DATE: "Which date suits you well?",
                DATE_PLACEHOLDER: "As quickly as possible",

                PART: "Which part of the day works best?"
            }
        }
    }
};


export default translations;