import React from "react";

// Components
import { ButtonPrimary } from "../../buttons";

const Contact  = ({ setModal, text }) => (
    <section className="container wrap flex flex__center">
        <div className="container__content center">
            <h1 className="header">{text.TITLE}</h1>
            <div className="pt__m">
                <ButtonPrimary
                    className="bg__1 mt__m"
                    onClick={() => setModal({ open: true, appointment: true })}
                >
                    {text.BTN_APPOINTMENT}
                </ButtonPrimary>
                <ButtonPrimary
                    className="bg__1 mt__m ml__m"
                    onClick={() => setModal({ open: true, appointment: false })}
                >
                    {text.BTN_CALLBACK}
                </ButtonPrimary>
            </div>
        </div>
    </section>
);

export default Contact;