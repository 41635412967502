import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Configuration file for Firebase not included in the repository
import { config } from "./config";

const app = initializeApp(config);
const db = getFirestore(app);

export { db };
