import React from "react";

// Components
import Modal from "../modal";
import Callback from "./callback";
import Appointment from "./appointment";

const ModalContact = ({ open, toggle, appointment, text }) => {
    return (
        <Modal open={open} toggle={() => toggle({ open: false, appointment })}>
            {appointment ? (
                <Appointment
                    text={text.APPOINTMENT}
                    toggle={() => toggle({ open: false, appointment })}
                />
            ) : (
                <Callback
                    text={text.CALLBACK}
                    toggle={() => toggle({ open: false, appointment })}
                />
            )}
        </Modal>
    );
};

export default ModalContact;