import React from "react";

const Corporation = ({ text }) => (
    <section className="container wrap">
        <div className="container__content">
            <h1 className="header">{text.TITLE}</h1>
            <p className="pt__m">{text.DESCRIPTION[0]}</p>
            <p className="pt__m">{text.DESCRIPTION[1]}</p>
        </div>
    </section>
);

export default Corporation;