import React, { useState } from "react";
import { useSpring, useTrail, config, animated } from "react-spring";

// Components
import { IconSelect, IconRight } from "../icons";

export const Label = ({ name, className = null, required = false }) => (
	<div className={`c__secondary fw__b fs__xs pb__xs ${className}`}>
		{name}
		<span className="pl__xs c__main">
			{required ? "*" : "(niet verplicht)"}
		</span>
	</div>
);
export const Input = ({
	value,
	name,
	placeholder,
	type,
	className = null,
	onChange,
	pattern = null,
}) => (
	<input
		name={name}
		value={value}
		type={type || "text"}
		className={`input ${className}`}
		placeholder={placeholder}
		onChange={onChange}
		pattern={pattern}
	/>
);

export const Select = ({ reason, changeReason, options = [] }) => {
	const [openOptions, toggleOptions] = useState(false);

	const customConfig = { mass: 1, tension: 180, friction: 20 };

	const trail = useTrail(options.length, {
		config: customConfig,
		y: openOptions ? 0 : 200,
		d: openOptions ? 1 : 0,
		from: { opacity: 0, y: 200 },
	});

	const transition = useSpring({
		maxHeight: openOptions ? `${(options.length + 1) * 3.2}rem` : "3.2rem",
		config: config.stiff,
		duration: 500,
	});

	const transitionRotate = useSpring({
		transform: openOptions ? "rotate(180deg)" : "rotate(0deg)",
		duration: 500,
	});

	return (
		<animated.div className="select" style={transition}>
			<div
				type="button"
				className="select__trigger full flex flex__align flex__space align__left"
				onClick={() => toggleOptions(!openOptions)}
			>
				<span>{reason}</span>
				<animated.div style={transitionRotate}>
					<IconSelect className="icon icon__sm" />
				</animated.div>
			</div>

			{trail.map(({ y, d }, index) => {
				const selected = Boolean(reason === options[index]);
				return (
					<animated.button
						type="button"
						key={index}
						style={{
							opacity: d,
							transform: y.interpolate(
								(y) => `translate3d(0,${y}%,0)`
							),
						}}
						className={`option flex flex__align ${
							selected && "c__main"
						}`}
						onClick={() => {
							toggleOptions(!openOptions);
							changeReason(options[index]);
						}}
					>
						{selected && (
							<IconRight className="icon icon__sm mr__s" />
						)}
						{options[index]}
					</animated.button>
				);
			})}
		</animated.div>
	);
};
